
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/css/font-awesome";
@import "admin";
html { border-top: solid #1B452D 10px; }
body { background-color: #fff; }
.public {
  .header { margin-bottom:15px;}
  .nav-links { margin-top:65px;}
  .bump-line { padding-top:15px; color:#000;}
  .bump-line hr { border-color:#000;}
  .greenback {background:#1C462E; width: 100%; padding: 1.2em 0; color:#FAFAFA; font-size:2em; }
  .extra-padding { margin-top: 5em; margin-bottom: 5em;}
}

.background-grey {
  background-color: #f2f2f2;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23c9c9c9' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

